import React from "react";
import Layout from "../components/Layout";
import { Style } from "../components/Kontakt/styled";

export default function Kontakt({ location }) {
  return (
    <Layout
      location={location}
      title={"Gartenbau Dannenfelser | Kontakt"}
      description={"So können Sie uns erreichen - Wir freuen uns auf Sie!"}
    >
      <Style>
        <h2>Kontakt</h2>
        <span>Gartenbau </span>
        <span> Udo Dannenfelser GmbH</span>
        <span>Im Wert 5 </span>
        <span>67591 Mörstadt</span>
        <br />

        <div className={"row bold"}>
          <div className={"left"}>Telefon:</div>
          <div className={"right"}>06247-991701 </div>
        </div>
        <div className={"row bold"}>
          <div className={"left"}>Telefax:</div>
          <div className={"right"}>06247-991702</div>
        </div>
        <br />

        <span className={"bold"}> Bürozeiten: </span>
        <div className={"row bold"}>
          <div className={"left"}>Mo-Do:</div>
          <div className={"right"}>9.00 Uhr – 12.30 Uhr</div>
        </div>
        <div className={"row bold"}>
          <div className={"left"}>E-Mail:</div>
          <div className={"right"}>
            <a href={"mailto:verwaltung@gartenbau-dannenfelser.de"}>
              verwaltung@gartenbau-dannenfelser.de
            </a>
          </div>
        </div>
        <br />

        <span> Inhaltlich verantwortlich: </span>
        <span> Udo Dannenfelser </span>
        <br />
        <span> HRB #42456 </span>
        <span> USt ID: DE 268 495 136 </span>
      </Style>
    </Layout>
  );
}
