import styled from "styled-components";

export const Style = styled.div`
  span {
    display: block;
  }

  h2 {
    font-size: 3rem;
    margin: 1rem 0;
  }

  .row {
    display: flex;

    .left {
      width: 120px;
    }
    
  }
`;
